import LOCAL_STORAGE from "./localStorage";

const ETH_CHAIN_ID = "0x1";
const BASE_CHAIN_ID = "0x2105";
const BASE_TESTNET_ID = "0x14a34";

const ETH_MAINCHAIN = {
	chainId: ETH_CHAIN_ID,
	chainName: "Ethereum Mainnet",
	nativeCurrency: {
		name: "ETH",
		symbol: "ETH",
		decimals: 18,
	},
	rpcUrls: ["https://ethereum.publicnode.com"],
	blockExplorerUrls: ["https://etherscan.io/"],
};

const BASE_MAINCHAIN = {
	chainId: BASE_CHAIN_ID,
	chainName: "Base",
	nativeCurrency: {
		name: "ETH",
		symbol: "ETH",
		decimals: 18,
	},
	rpcUrls: ["https://mainnet.base.org"],
	blockExplorerUrls: ["https://basescan.org/"],
};


const BASE_TESTNET = {
	chainId: BASE_TESTNET_ID,
	chainName: "Base Sepolia Testnet",
	nativeCurrency: {
		name: "ETH",
		symbol: "ETH",
		decimals: 18,
	},
	rpcUrls: ["https://sepolia.base.org"],
	blockExplorerUrls: ["https://sepolia.basescan.org/"],
};

const TOKENS = {
};

// ------------------------------------- METHODS -------------------------------------
const getTokenAddress = ({ token }) => {
	return TOKENS[token];
};

const getChain = (useTestnet) => {
	return useTestnet ? BASE_TESTNET : BASE_MAINCHAIN;
};

const getChainId = (useTestnet) => {
	return parseInt(useTestnet ? BASE_TESTNET_ID : BASE_CHAIN_ID, 16);
};

const switchChain = async (useTestnet) => {
	try {
		const chain = BlockchainHelper.getChain(useTestnet);

		let stateData = LOCAL_STORAGE.loadState() || {};
		const ts = +new Date();
		if (stateData.switchChain && ts - stateData.switchChainTs < 120 * 1000) {
			if (stateData.switchChain.chainId !== chain.chainId) {
				//skip switching chain, it's a recent payment
				return;
			}
		}

		const {ethereum} = window;
		const switchNetwork = async () => {
			//console.debug(chain);
			try {
				if (1 * ethereum.networkVersion === chain.chainId) {
					return;
				}
				await ethereum.request({
					method: "wallet_switchEthereumChain",
					params: [{chainId: chain.chainId}],
				});
			} catch (switchError) {
				const msg = switchError?.message || "";
				if (!msg.includes("already pending") && !msg.includes("User rejected")) {
					try {
						if (1 * ethereum.networkVersion === chain.chainId) {
							return;
						}

						await ethereum.request({
							method: "wallet_addEthereumChain",
							params: [chain],
						});
					} catch (error) {
						console.debug(error);
					}
				}
			}
		};

		const productChainId = getChainId(useTestnet);
		if (1 * ethereum.networkVersion !== productChainId) {
			await switchNetwork();
		}
	} catch (e) {
		console.log(e);
	}
}

const BlockchainHelper = {
	getChainId,
	getChain,
	getTokenAddress,
	switchChain
};

export default BlockchainHelper;

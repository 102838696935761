import React, { useState, useEffect } from "react";
import cx from "classnames";
import s from "./MintPage.module.scss";
import { Image } from 'react-bootstrap';

import Header from "../../components/Header/Header";
import AppConfig from "../../AppConfig";
import MintButton from "../../components/MintButton/MintButton";
import AppImages from "../../AppImages";
import { useHistory } from "react-router";
import WalletHelper from "../../helpers/WalletHelper";
import BlockchainHelper from "../../helpers/BlockchainHelper";
import CopyWidget from "../../components/CopyWidget/CopyWidget";
import Footer from "../../components/Footer/Footer";
import TwoColumnLayout from "../../assets/styles/_twocolumns";
import { Button } from "reactstrap";


export default function MintPage({ user }) {
	const history = useHistory();
	const isMobile = window.innerWidth <= 768;
	const secondaryButtonClick = () => {
        window.location.href = 'https://opensea.io/account?search[collections][0]=poop-on-base';
	}


	useEffect(() => {
		if (user?.metamaskAccount) {
			BlockchainHelper.switchChain(false).then(() =>
				WalletHelper.checkIfUserHasNFT(user?.metamaskAccount).then((data) => {
					if (data) {
						history.push("/nft");
					}
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.metamaskAccount]);

	const [userHasNFT, setUserHasNFT] = useState(false);

	useEffect(() => {
    	WalletHelper.checkIfUserHasNFT(user.metamaskAccount).then((hasNFT) => {
			setUserHasNFT(false);
			//setUserHasNFT(hasNFT);
   		 });
	}, [user.metamaskAccount]);	


return (
	<div className={s.root}>
		<Header user={user} />
		<div className={s.content}>
			<TwoColumnLayout
				content1={
					<div className="column"> 
						<div className={s.title}>Connected!</div>

						<div className={s.description}>
							Perfect, now click below to mint your very own NFT!<br/>
							<br/>
							Once that’s done, you’ll see your NFT.<br/>
							<br/>
							Thanks for supporting Team 💩!
						</div>

						<div className={s.buttons}>
							<MintButton />
						</div>
						{userHasNFT ? (
							<div>
                            <div className={s["horizontal-line"]}></div> 

                            <div className={s.subtitle}>
                                 Looks like you have multiple Cryptopoops in your wallet!
                            </div>  
				
							<Button color="primary" className="btn-border" onClick={secondaryButtonClick}>
								CLICK TO BROWSE YOUR COLLECTION ON OPENSEA
							</Button>

                        	</div>
						):(null)}
						
						

					</div>
				}
				content2={
					<div className="column">
						<div>
            				<Image src={AppImages.ThreePoops} alt="Three Poop Stickers" fluid />
        				</div>
						
					</div>
				}
			/>
		</div>	
		<Footer />
	</div>
);
}

import React from "react";

import s from "./Footer.module.scss";
import AppConfig from "../../AppConfig";
import Logo from "../Logo/Logo";

export default function Footer() {
	return (
		<div className={s.root}>
			<div className={s.logo}>
				<Logo url={AppConfig.CPAI_URL} />
			</div>
		</div>
	);
}

import React from "react";

export default function HelpIcon({
	color = "#E9ECE7",
	width = 48,
	height = 48,
}) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_392_5199)">
				<path
					d="M23.9999 17.3335C20.3238 17.3335 17.3333 20.3241 17.3333 24.0002C17.3333 27.6763 20.3238 30.6668 23.9999 30.6668C27.676 30.6668 30.6666 27.6763 30.6666 24.0002C30.6666 20.3241 27.676 17.3335 23.9999 17.3335ZM25.4766 27.4913C25.1094 27.6357 23.8366 28.2435 23.1033 27.5974C22.8844 27.4052 22.7755 27.1613 22.7755 26.8652C22.7755 26.3107 22.9577 25.8274 23.286 24.6668C23.3438 24.4474 23.4144 24.1629 23.4144 23.9374C23.4144 23.5479 23.2666 23.4446 22.866 23.4446C22.6705 23.4446 22.4538 23.5141 22.2577 23.5874L22.366 23.1435C22.8033 22.9657 23.3521 22.7491 23.8221 22.7491C24.5271 22.7491 25.046 23.1007 25.046 23.7696C25.046 23.9624 25.0127 24.3002 24.9427 24.5335L24.5371 25.9679C24.4533 26.2579 24.3016 26.8974 24.5366 27.0868C24.7677 27.2741 25.3149 27.1746 25.5849 27.0474L25.4766 27.4913ZM24.8066 21.7779C24.3466 21.7779 23.9733 21.4046 23.9733 20.9446C23.9733 20.4846 24.3466 20.1113 24.8066 20.1113C25.2666 20.1113 25.6399 20.4846 25.6399 20.9446C25.6399 21.4046 25.2666 21.7779 24.8066 21.7779Z"
					fill={color}
				/>
			</g>
			<rect
				x="1"
				y="1"
				width="46"
				height="46"
				rx="3"
				stroke={color}
				strokeWidth="2"
			/>
			<defs>
				<clipPath id="clip0_392_5199">
					<rect
						width="13.3333"
						height="13.3333"
						fill="white"
						transform="translate(17.3333 17.3335)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

import ThreePoops from "./assets/images/cpai-three-poops.png";
import MobileWizard from "./assets/images/mobile-mint-wizard.png";
import AccessDenied from "./assets/images/general/access-denied.svg";
import AppLogo from "./components/Logo/Logo.svg";
import Instructions from "./assets/images/instructions.jpg";
import MagicHatImg from "./assets/images/mint-magic-hat.svg";
import NFTImg from "./assets/images/nfts/2.png";
import Ethereum from "./assets/images/general/ethereum.svg";
import Base from "./assets/images/general/base.svg";
import SearchingGif from "./assets/images/general/searching.gif";


import BankruptImage from "./assets/images/payment/ic-insufficient.svg";
import ErrorImage from "./assets/images/general/ic-error.svg";
import LoadingGif from "./assets/images/payment/loading.gif";
import EmptyImage from "./assets/images/payment/empty.svg";

// METAMASK STEPS MEDIA
import GeneratingIcon from "./assets/images/payment/loading.gif";
import NetworkIcon from "./assets/images/metamask-steps/ic-network.svg";
import PaymentIcon from "./assets/images/metamask-steps/ic-payment.svg";
import CompletedIcon from "./assets/images/metamask-steps/cpai-completed.svg";
import ActiveIcon from "./assets/images/metamask-steps/cpai-active.svg";
import InActiveIcon from "./assets/images/metamask-steps/cpai-inactive.svg";

// Help Page Images
import ViewNFT from "./assets/images/help/view-nft.png";
import WiewProfile from "./assets/images/help/view-profile.png";
import GetAddress from "./assets/images/help/get-address.png";
import ImportNFT from "./assets/images/help/import-nft.png";

const AppImages = {
	AppLogo,
	Instructions,
	AccessDenied,
	ThreePoops,
	SearchingGif,
	MobileWizard,
	
	NFTImg,
	base: Base,
	ethereum: Ethereum,

	INSUFFICIENT_FUNDS_IMG: BankruptImage,
	PURCHASE_ERROR_IMG: ErrorImage,
	LOADING_GIF: LoadingGif,
	EMPTY_IMG: EmptyImage,

	METAMASK_STEPS_ICONS: {
		CompletedIcon,
		ActiveIcon,
		InActiveIcon,
		NetworkIcon,
		PaymentIcon,
		GeneratingIcon,
	},

	Help: {
		ViewNFT,
		WiewProfile,
		GetAddress,
		ImportNFT,
	}
};

export default AppImages;

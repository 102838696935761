const NFT_ADDRESS = "0x45a23BE83D8AC254541aEC105aB2650526438491";

const AppConfig = {
	AppName: "CryptoPoop.ai",
	baseUrl: "/",

	METAMASK_DOWNLOAD_URL: "https://metamask.io/download/",
	TERMS_URL: "https://CryptoPoop.ai/privacy-policy",
	PRIVACY_POLICY_URL: "https://CryptoPoop.ai/gdpr",
	NFT_TOKEN_URI: NFT_ADDRESS,

	APP_TITLE: "CryptoPoop.AI",
	CURRENCY: "USDC",
	
	DEFAULT_GAS_PRICE: '100000',
	GAS_LIMIT: '5000000',
	TRANSACTION_GAS_LIMIT: '200000',

	PAGE_SIZE: 50,

	OPENSEA_URL: "https://opensea.io/",
	OPENSEA_COLLECTION_URL: "https://opensea.io/assets/base/" + NFT_ADDRESS,

	PROOFS_LIMIT: 5000
};

export default AppConfig;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function TwoColumnLayout({ content1, content2 }) {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{
        height: '95vh',
        maxHeight: '93vh',
        maxWidth: '95vw',
        backgroundColor: 'rgba(10, 6, 23, .7)',
        paddingTop: '80px', // Adjust this value based on your header height
      }}
    >
      <Row className="justify-content-center w-100">
        <Col md={5} className="d-flex align-items-center justify-content-center">
          <div>{content1}</div>
        </Col>
        <Col md={5} className="d-flex align-items-center justify-content-center">
          <div>{content2}</div>
        </Col>
      </Row>
    </Container>
  );
}

export default TwoColumnLayout;

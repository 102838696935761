import React from "react";
import { Link } from "react-router-dom";
import classNames from "./Logo.module.scss";
import AppImages from "../../AppImages";
import logoImage from './Logo.svg';

export default function Logo({ url }) {
    const mainUrl = url || "/";

    return (
        <div className="container-fluid">
            {!url ? (
                <Link to={mainUrl} className={classNames.root}>
                    <div className={classNames.logo}>
                        <img src={logoImage} alt="CPAI Logo" className={classNames.logo} />
                    </div>
                </Link>
            ) : (
                <a
                    href={mainUrl}
                    className={classNames.root}
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className={classNames.logo}>
                        <img src={AppImages.AppLogo} alt="CPAI Logo" className={classNames.logo} />
                    </div>
                </a>
            )}
        </div>
    );
}
import React from "react";
import cx from "classnames";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import s from "./InsufficientFundsDialog.module.scss";

import AppMessages from "../../../AppMessages";
import AppImages from "../../../AppImages";

export default function InsufficientFundsDialog({
	close = () => {},
	onContinue = () => {},
}) {
	const { INSUFFICIENT_FUNDS_IMG } = AppImages;

	return (
		<Modal centered isOpen={true} className={cx(s.root, "dialog-md")}>
			<ModalHeader toggle={close}>
				<p className={s.title}>Insufficient Funds</p>
			</ModalHeader>

			<ModalBody>
				<div className={s.logoContainer}>
					<img src={INSUFFICIENT_FUNDS_IMG} alt="logo" />
				</div>

				<p className={s.description}>
					{AppMessages.InsufficientFundsDialogText}
				</p>
			</ModalBody>

			<ModalFooter>
				<div className={s.buttons}>
					<Button color="primary" className="btn-border" onClick={close}>
						CANCEL
					</Button>
					<Button color="primary" onClick={onContinue}>
						BACK
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	);
}

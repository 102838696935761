import React, { useState } from "react";
import { Container } from "reactstrap";
import { useMetaMask } from "metamask-react";
import { useHistory } from "react-router-dom";

import AppConfig from "../../../AppConfig";

import s from "./ConnectMetamaskPage.module.scss";

import DownloadMetamaskDialog from "../../../components/metamask/DownloadMetamaskDialog/DownloadMetamaskDialog";
import Header from "../../../components/Header/Header";
import MetamaskCheckHelper from "../../../helpers/MetamaskCheckHelper";
import MetamaskConfirmationDialog from "../../../components/metamask/MetamaskConfirmationDialog/MetamaskConfirmationDialog";
import MetamaskConnectPanel from "../../../components/metamask/MetamaskConnectPanel/MetamaskConnectPanel";
import TermsDialog from "../../../components/metamask/TermsDialog/TermsDialog";
import Footer from "../../../components/Footer/Footer";
import TwoColumnLayout from "../../../assets/styles/_twocolumns";

export default function ConnectMetamaskPage() {
	const history = useHistory();
	const { status, connect } = useMetaMask();

	const [showTermsDialog, setShowTermsDialog] = useState(false);
	const [showDownloadDialog, setShowDownloadDialog] = useState(false);
	const [downloadStarted, setDownloadStarted] = useState(false);

	const showDownloadMetamask = status === "unavailable";

	// ------------------------------------- METHODS -------------------------------------
	const connectToMetamask = () => {
		connect().then(() => {
			history.push("/");
		});
	};

	const acceptTerms = () => {
		setShowTermsDialog(false);

		// Not installed
		if (showDownloadMetamask) {
			setShowDownloadDialog(true);
		} else {
			connectToMetamask();
		}
	};

	const downloadMetamask = () => {
		setShowDownloadDialog(false);
		setDownloadStarted(true);
		window.open(AppConfig.METAMASK_DOWNLOAD_URL, "_blank");
	};

	return (
		<Container fluid className={s.root}>
			<div className={s.container}> 
				<MetamaskCheckHelper triggered={downloadStarted} />

				<Header />

				{status === "connecting" ? <MetamaskConfirmationDialog /> : null}

				{showTermsDialog ? (
					<TermsDialog
						onContinue={acceptTerms}
						close={() => setShowTermsDialog(false)}
					/>
				) : null}

				{showDownloadDialog ? (
					<DownloadMetamaskDialog
						onContinue={downloadMetamask}
						close={() => setShowDownloadDialog(false)}
					/>
				) : null}

				<div className={s.content}>
					<TwoColumnLayout
						content1={
							<div className={`column ${s.centeredContent}`}> 
								<div className={s.title}>
									Connect Wallet to Mint NFT for Someone Else
								</div>

								<div className={s.description}>
									{`Welcome to the CryptoPoop.ai homepage for minting someone else's NFT!`}
								</div>	
								<div className={s.description}>
									{`To start, please connect your wallet.`}
								</div>
							</div>
						}
						content2={
							<div className={`column ${s.centeredContent}`}>		
								<MetamaskConnectPanel onLogin={acceptTerms} />
							</div>
						}	
					/>
				</div>
				</div>
				<Footer />
			
		</Container>
	);
}

import React, { useState } from "react";
import cx from "classnames";
import { useMetaMask } from "metamask-react";
import { useHistory } from "react-router-dom";

import AppConfig from "../../AppConfig";

import s from "./ConnectMetamaskPage.module.scss";

import MetamaskConfirmationDialog from "../../components/metamask/MetamaskConfirmationDialog/MetamaskConfirmationDialog";
import TermsDialog from "../../components/metamask/TermsDialog/TermsDialog";
import DownloadMetamaskDialog from "../../components/metamask/DownloadMetamaskDialog/DownloadMetamaskDialog";
import MetamaskConnectPanel from "../../components/metamask/MetamaskConnectPanel/MetamaskConnectPanel";
//import Instructions from "../../components/Instructions/Instructions";
import Header from "../../components/Header/Header";
import MetamaskCheckHelper from "../../helpers/MetamaskCheckHelper";
import AppImages from "../../AppImages";
import CopyWidget from "../../components/CopyWidget/CopyWidget";
import Footer from "../../components/Footer/Footer";
import TwoColumnLayout from "../../assets/styles/_twocolumns";

export default function ConnectMetamaskPage() {
	const history = useHistory();
	const { status, connect } = useMetaMask();

	const [showTermsDialog, setShowTermsDialog] = useState(false);
	const [showDownloadDialog, setShowDownloadDialog] = useState(false);
	const [downloadStarted, setDownloadStarted] = useState(false);

	const showDownloadMetamask = status === "unavailable";

	// ------------------------------------- METHODS -------------------------------------
	const connectToMetamask = () => {
		connect().then(() => {
			history.push("/");
		});
	};

	const acceptTerms = () => {
		setShowTermsDialog(false);

		// Not installed
		if (showDownloadMetamask) {
			setShowDownloadDialog(true);
		} else {
			connectToMetamask();
		}
	};

	const downloadMetamask = () => {
		setShowDownloadDialog(false);
		setDownloadStarted(true);
		window.open(AppConfig.METAMASK_DOWNLOAD_URL, "_blank");
	};

	return (
		<div className={s.root}> 
			<div className={s.container}> 
				<MetamaskCheckHelper triggered={downloadStarted} />
				<Header />
	
				{status === "connecting" ? <MetamaskConfirmationDialog /> : null}
	
				{showTermsDialog ? (
					<TermsDialog
						onContinue={acceptTerms}
						close={() => setShowTermsDialog(false)}
					/>
				) : null}
	
				{showDownloadDialog ? (
					<DownloadMetamaskDialog
						onContinue={downloadMetamask}
						close={() => setShowDownloadDialog(false)}
					/>
				) : null}
	
				<div className={s.content}>
					<TwoColumnLayout
					content1={
						<div className="column">
							<div className={s.title}><h1>Welcome</h1></div>
				
								<div className={s.description}>
									Welcome to the CryptoPoop.ai homepage for minting 
									your very own NFT! <br/>
									Thanks for supporting Team 💩!
								</div>

								<div className={cx(s.description, s.mt)}>
									To start, please connect your wallet.
								</div>
						</div>
					}
					content2={
						<div className="column">
							{/* CONNECT PANEL */}
							<MetamaskConnectPanel onLogin={acceptTerms} />
						</div>
					
					}
				/>	
				</div>
			</div>
	
			{/* Footer is outside the container */}
			<Footer />
		</div>
	);
				}
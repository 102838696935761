const AppMessages = {
	InsufficientFundsDialogText:
		"We couldn't complete the transaction. Please make sure your wallet contains the necessary funds and try again.",

	ErrorDialogTitle: "Something went wrong",
	ErrorDialogText:
		"We're sorry, something went wrong, and the transaction was not processed. Please try again.",

	LoginDialogTitle: "Metamask is not connected",
	LoginDialogText: "You need to connect with Metamask to purchase this report.",

	ADMIN_MINT_BUTTON_TEXT: "CLICK TO MINT & SEND NFT",
	ADMIN_NFT_BUTTON_TEXT: "MINT & SEND ANOTHER NFT ",
	MINT_BUTTON_TEXT: "CLICK TO MINT NFT FOR .05 ETH",
};

export default AppMessages;

import React, { useState, useEffect } from "react";

import { Switch, Route, Redirect } from "react-router";
import { useLocation } from "react-router";
import { useMetaMask } from "metamask-react";

import AdminConnectMetamaskPage from "./admin/ConnectMetamaskPage/ConnectMetamaskPage";
import AdminMintPage from "./admin/AdminMintPage/AdminMintPage";
import AdminNFTPage from "./admin/AdminNFTPage/AdminNFTPage";
import AdminErrorPage from "./admin/ErrorPage/ErrorPage";
import WalletHelper from "../helpers/WalletHelper";
import AdminConsolePage from "./admin/AdminConsolePage";
import BlockchainHelper from "../helpers/BlockchainHelper";

export default function AdminApp({ user }) {
	const meta = useMetaMask();
	const location = useLocation();
	const [hasNFT, setHasNFT] = useState(null);

	const isLoggedIn = !!meta.account;

	const whitelisted = true;

	useEffect(() => {
		BlockchainHelper.switchChain(false).then(() =>
			WalletHelper.checkIfUserHasNFT(meta.account).then((r) => {
				setHasNFT(r);
			})
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meta.account]);

	// ------------------------------------- METHODS -------------------------------------
	const PrivateRoute = ({ isLoggedIn, hasNFT, ...props }) => {
		return isLoggedIn ? (
			whitelisted ? (
				hasNFT && !(location?.pathname || "").startsWith("/admin") ? (
					<Redirect to={"/admin/nft"} />
				) : (
					<Route {...props} />
				)
			) : (
				<Redirect to={"/admin/denied"} />
			)
		) : (
			<Redirect to={"/admin/login"} />
		);
	};

	const AnonymousRoute = ({ isLoggedIn, ...props }) => {
		return isLoggedIn ? <Redirect to={"/admin"} /> : <Route {...props} />;
	};

	return (
		<Switch>
			<AnonymousRoute
				isLoggedIn={isLoggedIn}
				exact
				path="/admin/login"
				component={AdminConnectMetamaskPage}
			/>

			<PrivateRoute
				isLoggedIn={isLoggedIn}
				exact
				path="/admin/denied"
				render={() => <AdminErrorPage user={user} />}
			/>

			<PrivateRoute
				isLoggedIn={isLoggedIn}
				exact
				path="/admin/nft"
				render={() => <AdminNFTPage user={user} />}
			/>

			<PrivateRoute
				isLoggedIn={isLoggedIn}
				path={"/admin/history"}
				render={() => <AdminConsolePage user={user} />}
			/>

			<PrivateRoute
				isLoggedIn={isLoggedIn}
				hasNFT={hasNFT}
				exact
				path="/admin"
				render={() => <AdminMintPage user={user} />}
			/>
		</Switch>
	);
}

import React from "react";

export default function HomeIcon({
	color = "#E9ECE7",
	width = 48,
	height = 48,
}) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_309_404)">
				<path
					d="M30.0038 20.7387L28.4038 18.072C28.3564 17.9931 28.2894 17.9278 28.2093 17.8825C28.1292 17.8372 28.0387 17.8134 27.9467 17.8133H19.4134C19.3213 17.8134 19.2309 17.8372 19.1508 17.8825C19.0707 17.9278 19.0037 17.9931 18.9563 18.072L17.3563 20.7387C17.3064 20.8216 17.2801 20.9166 17.28 21.0133C17.279 21.5779 17.5022 22.1197 17.9006 22.5197C18.2989 22.9198 18.8398 23.1453 19.4043 23.1467H19.4086C19.9354 23.148 20.4438 22.9531 20.8347 22.6C21.2245 22.9523 21.7312 23.1473 22.2566 23.1473C22.782 23.1473 23.2886 22.9523 23.6784 22.6C24.0684 22.953 24.5756 23.1485 25.1016 23.1485C25.6276 23.1485 26.1349 22.953 26.5248 22.6C26.8309 22.8762 27.2106 23.0575 27.6178 23.122C28.025 23.1865 28.4422 23.1314 28.8187 22.9634C29.1952 22.7954 29.5147 22.5217 29.7387 22.1755C29.9626 21.8293 30.0812 21.4256 30.08 21.0133C30.08 20.9166 30.0536 20.8216 30.0038 20.7387V20.7387Z"
					fill={color}
				/>
				<path
					d="M27.9553 24.2133C27.4596 24.2137 26.9705 24.099 26.5265 23.8784L26.5185 23.8821C26.1438 24.0689 25.7358 24.1797 25.318 24.2081C24.9002 24.2365 24.481 24.1819 24.0844 24.0474C23.9457 24.0008 23.8103 23.9448 23.6791 23.88L23.6732 23.8826C23.2986 24.0696 22.8906 24.1804 22.4729 24.2087C22.0552 24.237 21.636 24.1822 21.2396 24.0474C21.101 24.0008 20.9658 23.9448 20.8348 23.88C20.3916 24.1 19.9034 24.2141 19.4087 24.2133C19.2315 24.2116 19.0547 24.1955 18.8801 24.1653V29.5466C18.8801 29.6881 18.9363 29.8237 19.0363 29.9237C19.1364 30.0238 19.272 30.08 19.4135 30.08H22.6135V26.88H24.7468V30.08H27.9468C28.0882 30.08 28.2239 30.0238 28.3239 29.9237C28.4239 29.8237 28.4801 29.6881 28.4801 29.5466V24.1632C28.3069 24.1942 28.1313 24.211 27.9553 24.2133V24.2133Z"
					fill={color}
				/>
			</g>
			<rect
				x="1"
				y="1"
				width="46"
				height="46"
				rx="3"
				stroke={color}
				strokeWidth="2"
			/>
			<defs>
				<clipPath id="clip0_309_404">
					<rect
						width="12.8"
						height="12.8"
						fill="white"
						transform="translate(17.28 17.28)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
